import React from "react";

export function YouTube(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8386 5.15941C22.1792 5.51057 22.4212 5.94541 22.54 6.42C22.8572 8.1787 23.0112 9.96295 23 11.75C23.0063 13.5103 22.8523 15.2676 22.54 17C22.4212 17.4746 22.1792 17.9094 21.8386 18.2606C21.498 18.6118 21.0707 18.8668 20.6 19C18.88 19.46 12 19.46 12 19.46C12 19.46 5.11996 19.46 3.39996 19C2.93878 18.8738 2.51794 18.6308 2.17811 18.2945C1.83827 17.9581 1.59092 17.5398 1.45996 17.08C1.14273 15.3213 0.988741 13.537 0.999961 11.75C0.991197 9.97631 1.14518 8.20556 1.45996 6.46C1.57875 5.98541 1.82068 5.55057 2.16131 5.19941C2.50194 4.84824 2.92921 4.59318 3.39996 4.46C5.11996 4 12 4 12 4C12 4 18.88 4 20.6 4.42C21.0707 4.55318 21.498 4.80824 21.8386 5.15941ZM15.5 11.75L9.75 15.02V8.47998L15.5 11.75Z"
      />
    </svg>
  );
}
